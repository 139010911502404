import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  .cam-status-badge {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 80px;
    height: 30px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    .cam-status-text {
      width: 100%;
      text-align: center;
      font-family: 'Prompt-Medium', sans-serif;
      font-size: 10px;
      font-weight: normal;
      line-height: 1.25;
      color: ${(props) => props.theme.greenFont};
    }
  }
`
