import React from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'

import CameraMarker from '../CameraMarker/Loadable'
import MapCameraComponentStyled from './styledComponent'
import { CAMERA_ACTIVE, CAMERA_INACTIVE } from '../../utils'

class MapCameraComponent extends React.PureComponent {
  findCenterMap() {
    if (this.props.listCamera.length === 0) {
      return {
        lat: 0,
        lng: 0
      }
    }
    const listCoor = this.props.listCamera.map((cameraData) => cameraData.location)
    const listLat = listCoor.map((dat) => dat.lat)
    const listLng = listCoor.map((dat) => dat.lng)

    const minLat = Math.min(...listLat)
    const maxLat = Math.max(...listLat)

    const minLng = Math.min(...listLng)
    const maxLng = Math.max(...listLng)

    const centerLat = (minLat + maxLat) / 2
    const centerLng = (minLng + maxLng) / 2
    return {
      lat: centerLat,
      lng: centerLng
    }
  }

  renderAllCameraStatus() {
    let output = null
    const { listCamera } = this.props
    const allCameraCount = listCamera.length
    const activeCameraCount = listCamera.filter(
      (cameraData) => cameraData.camera_status.toUpperCase() === CAMERA_ACTIVE || cameraData.camera_status.toUpperCase() === CAMERA_INACTIVE
    ).length

    output = (
      <div className="cam-status-badge">
        <div className="cam-status-text text-style">{`${activeCameraCount}/${allCameraCount} ACTIVE`}</div>
      </div>
    )

    return output
  }

  generateListCameraMarker() {
    const cameraMarkers = this.props.listCamera.map((cameraData) => {
      return (
        <CameraMarker
          key={cameraData.camera_id}
          onClick={() => this.props.onCameraClicked(cameraData)}
          lat={cameraData.location.lat}
          lng={cameraData.location.lng}
          text={cameraData.name}
          status={cameraData.camera_status.toUpperCase()}
        />
      )
    })
    return cameraMarkers
  }

  render() {
    return (
      <MapCameraComponentStyled>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${process.env.GOOGLE_MAP_API_KEY}` }}
          defaultCenter={this.findCenterMap()}
          defaultZoom={16}
          options={{
            scrollwheel: false,
            draggable: false,
            fullscreenControl: false,
            zoomControl: false
          }}
        >
          {this.generateListCameraMarker()}
        </GoogleMapReact>
        {this.renderAllCameraStatus()}
      </MapCameraComponentStyled>
    )
  }
}

MapCameraComponent.propTypes = {
  listCamera: PropTypes.arrayOf(
    PropTypes.shape({
      camera_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      location: PropTypes.shape({
        lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      }).isRequired,
      camera_status: PropTypes.string
    })
  ).isRequired,
  onCameraClicked: PropTypes.func.isRequired
}

export default MapCameraComponent
