import styled from 'styled-components'

export default styled.div`
  display: flex;
  height: 295px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  .map-camera-container {
    width: 400px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    &:hover {
      cursor: default;
    }
  }

  .map-detail-card {
    width: 315px;
    .detail-content-container {
      background: white;
      height: calc(100% - 40px);
      .detail-row-container {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px 0 27px 20px;
          width: 100%;
          .title {
            font-family: 'Prompt', sans-serif;
            font-weight: bold;
            font-size: 10px;
            line-height: 1;
            text-transform: uppercase;
            color: rgba(31, 45, 57, 0.5);
            margin-bottom: 28px;
            &.subheader-long {
              font-size: 11px;
            }
          }
          .value {
            font-family: 'Prompt', sans-serif;
            font-size: 10px;
            font-weight: bold;
            color: ${(props) => props.theme.darkBackground};
            line-height: 0.63;
            text-align: right;
            padding-right: 20px;
            span {
              font-weight: bold;
              font-size: 54px;
              color: ${(props) => props.theme.orangeFont};
              padding-right: 10px;
            }
          }
        }
      }
      .detail-divide {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
`
