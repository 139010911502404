import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import MapAreaDetailStyled from './styledComponent'
import MapCameraComponent from '../MapCameraComponent'
import DivideLine from '../DivideLine/Loadable'
import SkeletonLoading from '../SkeletonLoading/Loadable'
import CardWrapper from '../CardWrapper/Loadable'

import MESSAGE from './message'

class MapAreaDetailCard extends React.PureComponent {
  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getAverageSubheader() {
    const filterWords = {
      day: this.getMessage('avg_violation_subheader_hr'),
      week: this.getMessage('avg_violation_subheader_day'),
      month: this.getMessage('avg_violation_subheader_day'),
      year: this.getMessage('avg_violation_subheader_month')
    }
    return filterWords[this.props.dateTimeFilterBy]
  }

  getDetailBox() {
    let subheaderClassName = ''
    if (this.getMessage('total_violation_subheader').length > 20) {
      subheaderClassName = ' subheader-long'
    }

    return (
      <CardWrapper cardTitle={this.getMessage('detail_header')} className="map-detail-card">
        <div className="detail-content-container">
          <div className="detail-row-container">
            <div className="content text-style">
              <div className={`title ${subheaderClassName}`}>{this.getMessage('total_violation_subheader')}</div>
              <div className="value">
                <span>{this.props.detail.totalViolationCount}</span>
                {pluralize(this.getMessage('count'), this.props.detail.totalViolationCount)}
              </div>
            </div>
          </div>
          <DivideLine className="detail-divide" />
          <div className="detail-row-container">
            <div className="content text-style">
              <div className={'title' + subheaderClassName}>{this.getAverageSubheader()}</div>
              <div className="value">
                <span>{this.props.detail.averageViolationCount}</span>
                {pluralize(this.getMessage('count'), this.props.detail.averageViolationCount)}
              </div>
            </div>
          </div>
        </div>
      </CardWrapper>
    )
  }

  getAreaDetailCard() {
    return (
      <>
        <div className="map-camera-container">
          <MapCameraComponent listCamera={this.props.listCamera} onCameraClicked={(cameraData) => this.props.onCameraClicked(cameraData)} />
        </div>
        {this.getDetailBox()}
      </>
    )
  }

  getContent() {
    const content = this.props.isLoading ? <SkeletonLoading /> : this.getAreaDetailCard()
    return content
  }

  render() {
    return <MapAreaDetailStyled style={this.props.style}>{this.getContent()}</MapAreaDetailStyled>
  }
}

MapAreaDetailCard.defaultProps = {
  language: 'EN'
}

MapAreaDetailCard.propTypes = {
  listCamera: PropTypes.arrayOf(
    PropTypes.shape({
      camera_id: PropTypes.number,
      camera_status: PropTypes.string,
      image: PropTypes.string,
      is_active: PropTypes.bool,
      location: PropTypes.shape({
        lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      }),
      name: PropTypes.string,
      resolution_height: PropTypes.number,
      resolution_width: PropTypes.number
    })
  ),
  detail: PropTypes.shape({
    areaName: PropTypes.string,
    cameraViolationRanking: PropTypes.arrayOf(
      PropTypes.shape({
        violation_count: PropTypes.number,
        camera_name: PropTypes.string,
        camera_id: PropTypes.number
      })
    ),
    mostViolationAreaName: PropTypes.string,
    mostViolationCount: PropTypes.number,
    totalViolationCount: PropTypes.number,
    averageViolationCount: PropTypes.number
  }),
  style: PropTypes.object,
  onCameraClicked: PropTypes.func,
  language: PropTypes.string,
  dateTimeFilterBy: PropTypes.string
}

export default MapAreaDetailCard
