const MESSAGE = {
  EN: {
    detail_header: 'DETAILS',
    total_violation_subheader: 'TOTAL VIOLATION',
    avg_violation_subheader_hr: 'AVERAGE VIOLATION (PER HOUR)',
    avg_violation_subheader_day: 'AVERAGE VIOLATION (PER DAY)',
    avg_violation_subheader_month: 'AVERAGE VIOLATION (PER MONTH)',
    count: 'COUNT'
  },
  TH: {
    detail_header: 'รายละเอียด',
    total_violation_subheader: 'จำนวนการฝ่าฝืนทั้งหมด (ครั้ง)',
    avg_violation_subheader_hr: 'จำนวนการฝ่าฝืนโดยเฉลี่ย (ต่อชม.)',
    avg_violation_subheader_day: 'จำนวนการฝ่าฝืนโดยเฉลี่ย (ต่อวัน)',
    avg_violation_subheader_month: 'จำนวนการฝ่าฝืนโดยเฉลี่ย (ต่อเดือน)',
    count: 'ครั้ง'
  }
}
export default MESSAGE
